import 'salad/static/fonts.css'
import * as Sentry from '@sentry/react'
import { SESSION_ID } from 'domains/analytics/constants'
import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import { BackofficeApp } from './bo-app'

const element = document.getElementById('root')

if (
  ['staging', 'production'].includes(process.env.BUILD_MODE) &&
  process.env.BRANCH_STAGING === undefined && // disable on PR preview stands
  process.env.PLAYWRIGHT === undefined // disable for test runs
) {
  Sentry.init({
    environment: process.env.BUILD_MODE,
    dsn: process.env.SENTRY_DSN,
    release: process.env.GIT_COMMIT_HASH,
    ignoreErrors: [/^(ManualCancellationError|AuthError|AbortError)/],
  })

  Sentry.setTag('sessionId', SESSION_ID)
}

if (element !== null) {
  const root = createRoot(element)

  root.render(
    <StrictMode>
      <BackofficeApp />
    </StrictMode>
  )
}
