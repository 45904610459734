import { QueryClientProvider } from '@tanstack/react-query'
import { IntlProvider } from 'domains/i18n/components'
import { CacheKey } from 'kitchen/constants'
import { ExtendedResponseError } from 'kitchen/utils/error'
import { queryClient, queryCache } from 'kitchen/utils/query-client'
import { Tooltip, Toast } from 'salad/components'

queryCache.config.onError = (error, query) => {
  const [queryKey] = query.queryKey
  if (
    error instanceof ExtendedResponseError &&
    error.response.status === 401 &&
    queryKey !== CacheKey.CURRENT_AGENT
  ) {
    // handled in <RequireAuth>
    queryClient.invalidateQueries([CacheKey.CURRENT_AGENT])
  }
}

interface ProvidersProps {
  children?: React.ReactNode
}

export const BOProviders = ({ children }: ProvidersProps) => {
  return (
    <QueryClientProvider client={queryClient}>
      <IntlProvider packageName="backoffice">
        <Toast.Provider>
          <Tooltip.Provider>{children}</Tooltip.Provider>
        </Toast.Provider>
      </IntlProvider>
    </QueryClientProvider>
  )
}
