import { Suspense } from 'react'
import { BOProviders } from './bo-providers'
import { BORoutes } from './bo-routes'
import { ErrorBoundary } from './components/error-boundary'
import { LoadingStatus } from './components/loading-status'
import { globalStyles } from './global-styles'

export function BackofficeApp() {
  globalStyles()
  return (
    <BOProviders>
      <ErrorBoundary>
        <Suspense fallback={<LoadingStatus layout="page" />}>
          <BORoutes />
        </Suspense>
      </ErrorBoundary>
    </BOProviders>
  )
}
